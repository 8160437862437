export const CALL_API = 'CALL_API';

// UI =========================================
export const TOGGLE_HEADER_LOGO_RIGHT = 'TOGGLE_HEADER_LOGO_RIGHT';
export const TOGGLE_HEADER_LOGO_LEFT = 'TOGGLE_HEADER_LOGO_LEFT';

// Projects =========================================
export const PROJECTS_FETCH_START = 'PROJECTS_FETCH_START';
export const PROJECTS_FETCH_SUCCESS = 'PROJECTS_FETCH_SUCCESS';
export const PROJECTS_FETCH_ERROR = 'PROJECTS_FETCH_ERROR';
