import React from 'react';
import loadingLogo from '../../images/ab-logo-type.png';

const LoadingLogo = () => {
  return (
    <div id="page-loading">
      <img src={loadingLogo} className="loading-logo" alt="Loading Logo" />
      <div className="loading-spinner" />
    </div>
  );
};

export default LoadingLogo;
