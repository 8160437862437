import {
  TOGGLE_HEADER_LOGO_RIGHT,
  TOGGLE_HEADER_LOGO_LEFT,
} from '../action-types';

export function shiftHeaderLogoRight() {
  return {
    type: TOGGLE_HEADER_LOGO_RIGHT,
  };
}

export function shiftHeaderLogoLeft() {
  return {
    type: TOGGLE_HEADER_LOGO_LEFT,
  };
}
