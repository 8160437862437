/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';
import { BASE_IMAGE_URL } from '../../../constants';

import closeInfoBox from '../../../images/icon-close-grey.png';

const MarkerInfoBox = ({ markerData, onClickCloseMapMarker }) =>
  (<InfoBox
    options={{
      closeBoxURL: '',
      enableEventPropagation: true,
      pixelOffset: new google.maps.Size(-32, -14),
      alignBottom: true,
      zIndex: null,
    }}
  >
    <div>
      <img
        src={closeInfoBox}
        className="infoBox__close"
        alt="Close"
        onClick={onClickCloseMapMarker}
      />
      <div id="infobox-wrap">
        <div
          className="image"
          style={{
            backgroundImage: `url(${BASE_IMAGE_URL}${markerData.photosThumbnail}`,
          }}
        />
        <div className="text">
          <h4>
            {markerData.photosName}
            {', '}
            {markerData.photosLocation}
          </h4>
          <span className="year">
            {markerData.photosYear}
          </span>
          <span className="year-line" />
          <a href={markerData.photosLink} target="_blank" rel="noopener noreferrer">
            View Photos
          </a>
        </div>
      </div>
    </div>
  </InfoBox>);

MarkerInfoBox.propTypes = {
  markerData: PropTypes.object.isRequired,
  onClickCloseMapMarker: PropTypes.func.isRequired,
};

export default MarkerInfoBox;
