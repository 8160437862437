import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MainNavigation from '../../components/shared/MainNavigation';
import {
  shiftHeaderLogoRight,
  shiftHeaderLogoLeft,
} from '../../redux/actions/ui';

import abCircleLogo from '../../images/ab-logo-type.png';

class Header extends Component {
  constructor(props) {
    super(props)

    this.onClickLogo = this.onClickLogo.bind(this);
  }
  
  shouldComponentUpdate(nextProps) {
    // Updates nav activeClass
    return nextProps.routingKey !== this.props.routingKey;
  }

  onClickLogo() { 
    this.props.setSelectedProject()
    this.props.onShiftHeaderLogoRight()
  }

  render() {
    const {
      isHeaderLogoShiftedRight,
      isFetchingProjects,
      routingKey,
      onShiftHeaderLogoRight,
    } = this.props;

    return (
      <header
        className={`clearfix ${isFetchingProjects
          ? 'header--loading'
          : 'header--loaded'}`}
      >
        <div className="logo-wrapper">
          <div
            className={`logo bg-noise shadow-md ${isHeaderLogoShiftedRight
              ? 'logo--shifted'
              : ''}`}
              >
            <Link to="/projects" onClick={this.onClickLogo}>
              <img
                className="shadow-lg"
                src={abCircleLogo}
                alt="Aaron Biser - UI Designer and Developer"
                />
            </Link>
          </div>
        </div>

        <MainNavigation routingKey={routingKey} onShiftHeaderLogoRight={onShiftHeaderLogoRight} />
      </header>
    );
  }
}

Header.propTypes = {
  isHeaderLogoShiftedRight: PropTypes.bool.isRequired,
  isFetchingProjects: PropTypes.bool.isRequired,
  onShiftHeaderLogoRight: PropTypes.func.isRequired,
  routingKey: PropTypes.string.isRequired,
  setSelectedProject: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const {
    ui: { isHeaderLogoShiftedRight },
    projects: { isFetching },
    routing: { location: { key } },
  } = state;

  return {
    isHeaderLogoShiftedRight,
    isFetchingProjects: isFetching,
    routingKey: key,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onShiftHeaderLogoRight: () => {
      dispatch(shiftHeaderLogoRight());
    },
    onShiftHeaderLogoLeft: () => {
      dispatch(shiftHeaderLogoLeft());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
