import React, { Component } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import PhotosMap from '../../components/photos/PhotosMap';
import PhotosList from '../../components/photos/PhotosList';
import { MAP_API } from '../../constants';

class Photos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mapData: [],
      isLoading: false,
      activeMapMarker: '',
      // apiError: '',
    };

    this.onClickMapMarker = this.onClickMapMarker.bind(this);
    this.onClickCloseMapMarker = this.onClickCloseMapMarker.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    fetch(MAP_API)
      .then(response => response.json())
      .then((data) => {
        this.setState({ mapData: data, isLoading: false });
      })
      .catch(error => this.setState({ apiError: error, isLoading: false }));
  }

  onClickMapMarker(index) {
    this.setState({ activeMapMarker: index });
  }

  onClickCloseMapMarker() {
    this.setState({ activeMapMarker: '' });
  }

  render() {
    const { mapData, activeMapMarker } = this.state;

    return (
      <ReactCSSTransitionGroup
        transitionName="photos"
        transitionAppearTimeout={750}
        transitionAppear
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}
      >
        <div id="photos-and-locations">
          <PhotosMap
            id="map"
            mapData={mapData}
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB9DCsFYwu3hKFz0N4cr5B7Tr_DOg3yA1s&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div id="map" />}
            mapElement={<div style={{ height: '100%' }} />}
            onClickMapMarker={this.onClickMapMarker}
            onClickCloseMapMarker={this.onClickCloseMapMarker}
            activeMapMarker={activeMapMarker}
          />

          <h2 className="header gradient-bg-green bg-noise shadow-md">
            <span className="lines before" />
            <span className="bold">Photos</span>{' '}
            <span>from places I've been</span>
            <span className="lines after" />
          </h2>

          <PhotosList mapData={mapData} />
        </div>
      </ReactCSSTransitionGroup>
    );
  }
}

export default Photos;
