import React from 'react';
import PropTypes from 'prop-types';

const date = new Date();
const year = date.getFullYear();

const Footer = ({ isFetchingProjects }) => {
  if (isFetchingProjects) {
    return false;
  }

  return (
    <footer>
      <p className="copyright">
        &#x24B8; {year}. Designed and developed by Aaron Biser
      </p>
    </footer>
  );
};

Footer.propTypes = {
  isFetchingProjects: PropTypes.bool.isRequired,
};

export default Footer;
