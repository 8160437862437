import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { addLeadingZero } from '../../scripts/helpers';

class ProjectListItem extends Component {
  constructor(props) {
    super(props);

    this._onMouseOverProject = this._onMouseOverProject.bind(this);
    this._onClickProject = this._onClickProject.bind(this);
  }

  _onMouseOverProject() {
    this.props.onMouseOverProject(this.props.projectIndex);
  }

  _onClickProject() {
    this.props.onClickProject(this.props.projectIndex);
  }

  render() {
    const {
      projectIndex,
      projectName,
      activeHoverProject,
      transitionDelay,
    } = this.props;
    const projectIndexZeroed = addLeadingZero(projectIndex, 2);

    return (
      <li
        className={`${activeHoverProject === projectIndex ? 'active' : ''}`}
        style={{ transitionDelay: `${transitionDelay}s` }}
        onMouseOver={this._onMouseOverProject}
        onClick={this._onClickProject}
      >
        <Link to={`/projects/${projectIndex}`}>
          <span className="number">
            {projectIndexZeroed}
          </span>
          <span className="name">
            {projectName}
          </span>
        </Link>
      </li>
    );
  }
}

ProjectListItem.propTypes = {
  activeHoverProject: PropTypes.number.isRequired,
  projectIndex: PropTypes.number.isRequired,
  projectName: PropTypes.string.isRequired,
  transitionDelay: PropTypes.number.isRequired,
  onMouseOverProject: PropTypes.func.isRequired,
  onClickProject: PropTypes.func.isRequired,
};

export default ProjectListItem;
