/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { BASE_IMAGE_URL } from '../../../constants';

const PhotosLocation = ({ photoSet }) =>
  (<a
    href={photoSet.photosLink}
    target="_blank"
    rel="noopener noreferrer"
    className="location"
    key={photoSet.photosId}
  >
    <div className="text shadow-md bg-noise gradient-bg-green">
      <h5 className="h6">
        {photoSet.photosName}
      </h5>
      <h4 className="h5">
        {photoSet.photosLocation}
      </h4>
    </div>
    <div
      className="image shadow-md"
      style={{
        backgroundImage: `url(${BASE_IMAGE_URL}${photoSet.photosThumbnail}`,
      }}
    >
      <span className="icon" />
    </div>
  </a>);

PhotosLocation.propTypes = {
  photoSet: PropTypes.object.isRequired,
};

export default PhotosLocation;
