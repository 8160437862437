import fetch from 'isomorphic-fetch';
import { PROJECTS_API } from '../../constants';
import {
  PROJECTS_FETCH_START,
  PROJECTS_FETCH_SUCCESS,
  PROJECTS_FETCH_ERROR,
} from '../action-types';

function requestProjects() {
  return {
    type: PROJECTS_FETCH_START,
  };
}

function receivedProjects(json) {
  return {
    type: PROJECTS_FETCH_SUCCESS,
    payload: json,
  };
}

export function requestProjectsError() {
  return {
    type: PROJECTS_FETCH_ERROR,
  };
}

export function getProjects() {
  return (dispatch) => {
    dispatch(requestProjects());

    return fetch(PROJECTS_API)
      .then(response => response.json())
      .then(json => dispatch(receivedProjects(json)));
  };
}
