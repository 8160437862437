import { combineReducers } from 'redux';
import {
  PROJECTS_FETCH_START,
  PROJECTS_FETCH_SUCCESS,
  PROJECTS_FETCH_ERROR,
} from '../action-types';

export function isFetching(state = false, action) {
  const { type } = action;

  switch (type) {
    case PROJECTS_FETCH_START:
      return true;
    case PROJECTS_FETCH_SUCCESS:
    case PROJECTS_FETCH_ERROR:
      return false;
    default:
      return state;
  }
}

export function projectsList(state = [], action) {
  const { type, payload } = action;

  switch (type) {
    case PROJECTS_FETCH_SUCCESS:
      return payload;
    default:
      return state;
  }
}

export default combineReducers({
  isFetching,
  projectsList,
});
