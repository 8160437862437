export function addLeadingZero(num, size) {
  let s = `${num}`;
  while (s.length < size) s = `0${s}`;
  return s;
}

export function getSiblingIndex(curIndex, elemLength, diff) {
  const index = curIndex + diff;
  if (index > elemLength) {
    return 1;
  } else if (index <= 0) {
    return elemLength;
  }
  return index;
}

export function scrollTo(distance, duration) {
  const initialY = document.body.scrollTop;
  const y = initialY - distance;
  const baseY = (initialY - y) * 0.5;
  const difference = initialY + baseY;
  const startTime = performance.now();

  function step() {
    let normalizedTime = (performance.now() - startTime) / duration;
    if (normalizedTime > 1) normalizedTime = 1;

    window.scrollTo(0, baseY + difference * Math.cos(normalizedTime * Math.PI));
    if (normalizedTime < 1) window.requestAnimationFrame(step);
  }
  window.requestAnimationFrame(step);
}

export function getImageHeight(url, imageWidth) {
  let imageHeight = null
  const imageStringArr = url.split('?')

  if (imageStringArr.length === 2) {
    const [width, height] = imageStringArr[1].split('X')
    imageHeight = Math.round(imageWidth / (width / height))
  }

  return imageHeight
}