import React from 'react';
import PropTypes from 'prop-types';
import MainNavigation from '../../components/shared/MainNavigation';

const MobileNav = ({ isMobileNavOpen, toggleMobileNav, closeMobileNav }) => {
  return (
    <div>
      {/* eslint-disable-next-line */}
      <a
        className={`expand-menu ${isMobileNavOpen ? 'active' : ''}`}
        onClick={toggleMobileNav}
      >
        <span className="line top" />
        <span className="line mid" />
        <span className="line bottom" />
      </a>
      <div className="mobile-nav">
        <MainNavigation closeMobileNav={closeMobileNav} />
      </div>
    </div>
  );
};

MobileNav.propTypes = {
  isMobileNavOpen: PropTypes.bool.isRequired,
  toggleMobileNav: PropTypes.func.isRequired,
  closeMobileNav: PropTypes.func.isRequired,
};

export default MobileNav;
