import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Route } from 'react-router-dom';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import ProjectListItem from '../../components/home/ProjectListItem';
import ProjectImages from '../../components/projects/ProjectImages';
import ProjectSingle from '../../components/projects/ProjectSingle';
import { getSiblingIndex } from '../../scripts/helpers';

class Projects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeHoverProject: '',
      error: null,
    };

    this.onMouseOverProject = this.onMouseOverProject.bind(this);
    this.onClickProject = this.onClickProject.bind(this);
    this.setProjectFromURL = this.setProjectFromURL.bind(this);
    this.onClickClose = this.onClickClose.bind(this);
  }

  onMouseOverProject(projectIndex) {
    this.setState({ activeHoverProject: projectIndex });
  }

  onClickProject(projectIndex) {
    this.props.setSelectedProject(projectIndex)
  }
  
  setProjectFromURL(index) {
    const projectIndex = parseFloat(index);
    this.props.history.push(`/projects/${projectIndex}`);
    
    this.props.setSelectedProject(projectIndex)
    
    this.setState({ activeHoverProject: projectIndex });
  }
  
  onClickClose() {
    this.props.setSelectedProject('')
    this.props.shiftHeaderLogoRight()
  }

  render() {
    const {
      projects,
      apiError,
      shiftHeaderLogoLeft,
      selectedProject
    } = this.props;

    const prevProjectIndex = getSiblingIndex(
      parseInt(selectedProject, 10),
      projects.length,
      -1
    );
    const nextProjectIndex = getSiblingIndex(
      parseInt(selectedProject, 10),
      projects.length,
      1
    );

    let transitionDelay = 0;

    return (
      <ReactCSSTransitionGroup
        transitionName="projects"
        transitionAppearTimeout={750}
        transitionAppear
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}
      >
        <div
          id="home-projects"
          className={selectedProject ? 'open-project' : null}
        >
          <div id="home-left">
            <div id="intro-text">
              <h1>Aaron Biser</h1>
              <h2>Developer & UI Designer</h2>
            </div>

            {apiError
              ? <div>
                {apiError}
              </div>
              : null}

            <nav className="projects">
              <ul>
                {projects.map((project, index) => {
                  transitionDelay += 0.05;

                  return (
                    <ProjectListItem
                      key={project.projectId}
                      activeHoverProject={parseInt(
                        this.state.activeHoverProject,
                        10
                      )}
                      projectIndex={index + 1}
                      projectName={project.projectName}
                      projectMainImage={project.projectMainImage}
                      onMouseOverProject={this.onMouseOverProject}
                      onClickProject={this.onClickProject}
                      transitionDelay={transitionDelay}
                    />
                  );
                })}
              </ul>
            </nav>
          </div>
          <Link
            to="/projects"
            className="close-project shadow-lg"
            onClick={this.onClickClose}
          >
            <span className="noise" />
          </Link>
          <ProjectImages
            projectsData={projects}
            currentProject={
              parseInt(selectedProject, 10) ||
              parseInt(this.state.activeHoverProject, 10)
            }
          />
        </div>
        <Route
          path="/projects/:project"
          render={(props) => {
            const currentProject =
              parseInt(selectedProject, 10) ||
              parseInt(this.state.activeHoverProject, 10);

            return (
              <ProjectSingle
                {...props}
                projects={projects}
                projectIndex={currentProject}
                project={projects[currentProject - 1] || {}}
                projectNextName={
                  projects[nextProjectIndex - 1]
                    ? projects[nextProjectIndex - 1].projectName
                    : ''
                }
                projectNextImage={
                  projects[nextProjectIndex - 1]
                    ? projects[nextProjectIndex - 1].projectThumbImage
                    : ''
                }
                projectNextIndex={nextProjectIndex}
                projectPrevName={
                  projects[prevProjectIndex - 1]
                    ? projects[prevProjectIndex - 1].projectName
                    : ''
                }
                projectPrevImage={
                  projects[prevProjectIndex - 1]
                    ? projects[prevProjectIndex - 1].projectThumbImage
                    : ''
                }
                projectPrevIndex={prevProjectIndex}
                onClickProject={this.onClickProject}
                setProjectFromURL={this.setProjectFromURL}
                shiftHeaderLogoLeft={shiftHeaderLogoLeft}
              />
            );
          }}
        />
      </ReactCSSTransitionGroup>
    );
  }
}

Projects.defaultProps = {
  projects: [],
  apiError: '',
  match: {},
};

Projects.propTypes = {
  projects: PropTypes.array,
  apiError: PropTypes.string,
  match: PropTypes.object,
  location: PropTypes.object,
  selectedProject: PropTypes.number.isRequired,
  setSelectedProject: PropTypes.func.isRequired,
  shiftHeaderLogoRight: PropTypes.func.isRequired,
  shiftHeaderLogoLeft: PropTypes.func.isRequired,
};

export default Projects;
