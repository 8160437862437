import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import PhotosLocation from '../../components/photos/widgets/PhotosLocation';

import flickrLogo from '../../images/flickr_logo.jpg';

const PhotosList = ({ mapData }) => {
  const mapDataByYear = _.groupBy(mapData, 'photosYear');

  return (
    <div id="photo-list">
      <div className="clearfix">
        <img src={flickrLogo} className="flickr-logo" alt="Flickr Logo" />
      </div>

      {Object.keys(mapDataByYear).reverse().map((key) => {
        const year = mapDataByYear[key];
        return (
          <div className="year-wrap clearfix" key={key}>
            <div className="year">
              <h5>
                {key}
              </h5>
            </div>
            <div className="locations">
              {year.map(photoSet =>
                <PhotosLocation photoSet={photoSet} key={photoSet.photosId} />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

PhotosList.propTypes = {
  mapData: PropTypes.arrayOf(
    PropTypes.shape({
      photosId: PropTypes.string.isRequired,
      photosYear: PropTypes.string.isRequired,
      photosThumbnail: PropTypes.string.isRequired,
      photosLatitude: PropTypes.string.isRequired,
      photosLongitude: PropTypes.string,
      photosName: PropTypes.string,
      photosLocation: PropTypes.string,
      photosLink: PropTypes.string,
    })
  ).isRequired,
};

export default PhotosList;
