import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import LazyLoad from 'react-lazyload';
import { BASE_IMAGE_URL } from '../../constants';
import { getImageHeight } from '../../scripts/helpers'
 
class ProjectScreenshots extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
      projectImagesWidth: 0
    };

    this.handleResize = this.handleResize.bind(this);
    this.openPhotoLightbox = this.openPhotoLightbox.bind(this);
  }

  componentDidMount() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize() {
    this.setState({ projectImagesWidth: this.projectImages.offsetWidth })
  }

  openPhotoLightbox(index) {
    this.setState({ photoIndex: index, isOpen: true });
  }

  render() {
    const { projectId, projectName, projectScreenshots } = this.props;
    const { photoIndex, isOpen } = this.state;

    const images = projectScreenshots;
    const imageBase = BASE_IMAGE_URL;
    
    return (
      <div
      id="project-images"
      ref={(ref) => {
        this.projectImages = ref;
      }}
      >
        {projectScreenshots.map((image, index) => {
          const key = `${projectId}-${index}`;
          const imageHeight = getImageHeight(image, this.state.projectImagesWidth)
          
          return (
            <LazyLoad 
              key={key}
              height={imageHeight} 
              offset={500} 
            >
              {/* eslint-disable-next-line */}
              <a
                className="project-image shadow-lg highlight-overlay"
                style={{ height: imageHeight }}
                onClick={() => this.openPhotoLightbox(index)}
              >
                <span className="fa fa-arrows-v" />
                <img
                  src={`${imageBase}${image}`}
                  alt={`${projectName} ${index + 1}`}
                  className="lazy"
                />
              </a>
            </LazyLoad>
          );
        })}
        {isOpen &&
          <Lightbox
            mainSrc={`${imageBase}${images[photoIndex]}`}
            nextSrc={`${imageBase}${images[(photoIndex + 1) % images.length]}`}
            prevSrc={`${imageBase}${images[
              (photoIndex + images.length - 1) % images.length
            ]}`}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })}
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })}
            imagePadding={30}
            enableZoom={false}
          />}
      </div>
    );
  }
}

ProjectScreenshots.defaultProps = {
  projectId: 0,
  projectScreenshots: [],
};

ProjectScreenshots.propTypes = {
  projectId: PropTypes.number,
  projectName: PropTypes.string.isRequired,
  projectScreenshots: PropTypes.array,
};

export default ProjectScreenshots;
