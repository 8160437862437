import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MainContent from './components/shared/MainContent';
import MobileNav from './components/shared/MobileNav';
import Header from './components/shared/Header';
import Footer from './components/shared/Footer';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projects: [],
      apiError: '',
      selectedProject: null,
      isMobileNavOpen: false,
    };

    this.setSelectedProject = this.setSelectedProject.bind(this);
    this.toggleMobileNav = this.toggleMobileNav.bind(this);
    this.closeMobileNav = this.closeMobileNav.bind(this);
  }

  setSelectedProject(selectedProject) {
    this.setState({ selectedProject })
  }

  toggleMobileNav() {
    this.setState({ isMobileNavOpen: !this.state.isMobileNavOpen });
  }

  closeMobileNav() {
    this.setState({ isMobileNavOpen: false });
  }

  render() {
    const { apiError, isMobileNavOpen } = this.state;
    const { isFetchingProjects } = this.props;

    return (
      <div className={isMobileNavOpen ? 'menu-open' : ''}>
        {!isFetchingProjects && (
          <MobileNav
            isMobileNavOpen={isMobileNavOpen}
            toggleMobileNav={this.toggleMobileNav}
            closeMobileNav={this.closeMobileNav}
          />
        )}

        <div id="site">
          <div id="bg-texture" className="bg-noise" />

          <Header isFetchingProjects={isFetchingProjects} setSelectedProject={this.setSelectedProject} />
          <MainContent
            isFetchingProjects={isFetchingProjects}
            apiError={apiError}
            selectedProject={this.state.selectedProject}
            setSelectedProject={this.setSelectedProject}
          />

          <Footer isFetchingProjects={isFetchingProjects} />
        </div>
      </div>
    );
  }
}

App.propTypes = {
  isFetchingProjects: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const { projects: { isFetching } } = state;

  return {
    isFetchingProjects: isFetching,
  };
}

export default withRouter(connect(mapStateToProps)(App));
