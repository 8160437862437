/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import MarkerInfoBox from '../../components/photos/widgets/MarkerInfoBox';
import { customMapStyles } from '../../scripts/customMapStyles';

import markerIcon from '../../images/marker@2x.png';

const PhotosMap = compose(
  // lifecycle({
  //   componentWillMount() {
  //     const refs = {};
  //
  //     this.setState({
  //       bounds: null,
  //       center: {
  //         lat: 41.9,
  //         lng: -87.624,
  //       },
  //       markers: [],
  //       onMapMounted: (ref) => {
  //         refs.map = ref;
  //
  //         const bounds = new google.maps.LatLngBounds();
  //
  //         this.props.mapData.forEach((place) => {
  //           const location = {
  //             lat: parseFloat(place.photosLatitude),
  //             lng: parseFloat(place.photosLongitude),
  //           };
  //
  //           bounds.extend(location);
  //         });
  //
  //         if (refs.map) {
  //           setTimeout(() => {
  //             refs.map.fitBounds(bounds);
  //           }, 1000);
  //         }
  //       },
  //       onBoundsChanged: () => {
  //         this.setState({
  //           bounds: refs.map.getBounds(),
  //           center: refs.map.getCenter(),
  //         });
  //         console.log('bounds: ', this.state.bounds);
  //         console.log('center: ', this.state.center);
  //       },
  //     });
  //   },
  // }),
  withScriptjs,
  withGoogleMap
)(props =>
  (<GoogleMap
    defaultZoom={3}
    defaultCenter={{ lat: 22.383118, lng: -63.8044817 }}
    onBoundsChanged={props.onBoundsChanged}
    defaultOptions={{ styles: customMapStyles }}
  >
    {props.mapData.map((marker, index) => {
      const customMarker = {
        url: markerIcon,
        scaledSize: new google.maps.Size(36, 36),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(0, 0),
      };

      return (
        <Marker
          key={marker.photosId}
          onClick={() => props.onClickMapMarker(index)}
          icon={customMarker}
          position={{
            lat: parseFloat(marker.photosLatitude),
            lng: parseFloat(marker.photosLongitude),
          }}
        >
          {props.activeMapMarker === index &&
            <MarkerInfoBox
              markerData={marker}
              onClickCloseMapMarker={props.onClickCloseMapMarker}
            />}
        </Marker>
      );
    })}
  </GoogleMap>)
);

PhotosMap.propTypes = {
  mapData: PropTypes.arrayOf(
    PropTypes.shape({
      photosId: PropTypes.string.isRequired,
      photosYear: PropTypes.string.isRequired,
      photosThumbnail: PropTypes.string.isRequired,
      photosLatitude: PropTypes.string.isRequired,
      photosLongitude: PropTypes.string,
      photosName: PropTypes.string,
      photosLocation: PropTypes.string,
      photosLink: PropTypes.string,
    })
  ).isRequired,
  googleMapURL: PropTypes.string.isRequired,
  loadingElement: PropTypes.node.isRequired,
  containerElement: PropTypes.node.isRequired,
  mapElement: PropTypes.node.isRequired,
  onClickMapMarker: PropTypes.func.isRequired,
  onClickCloseMapMarker: PropTypes.func.isRequired,
};

export default PhotosMap;
