import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const MainNavigationItem = ({ title, to, onClick }) => {
  return (
    <li>
      <NavLink to={`/${to}`} activeClassName="active" onClick={onClick}>
        {title}
        <span className="box gradient-bg-green" />
      </NavLink>
    </li>
  );
};

MainNavigationItem.defaultProps = {
  onClick: null,
};

MainNavigationItem.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default MainNavigationItem;
