import React from 'react';
import PropTypes from 'prop-types';

const ProjectMainImage = ({ projectIndex, projectImage, currentProject }) => {
  return (
    <div
      className={`main-image ${currentProject === projectIndex + 1
        ? 'active'
        : ''}`}
      style={{
        backgroundImage: `url(${projectImage})`,
      }}
    >
      {process.env.PUBLIC_URL}
    </div>
  );
};

ProjectMainImage.propTypes = {
  projectIndex: PropTypes.number.isRequired,
  currentProject: PropTypes.number.isRequired,
  projectImage: PropTypes.string.isRequired,
};

export default ProjectMainImage;
