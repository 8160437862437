import { combineReducers } from 'redux';
import {
  TOGGLE_HEADER_LOGO_RIGHT,
  TOGGLE_HEADER_LOGO_LEFT,
} from '../action-types';

export function isHeaderLogoShiftedRight(state = true, action) {
  const { type } = action;

  switch (type) {
    case TOGGLE_HEADER_LOGO_RIGHT:
      return true;
    case TOGGLE_HEADER_LOGO_LEFT:
      return false;
    default:
      return state;
  }
}

export default combineReducers({
  isHeaderLogoShiftedRight,
});
