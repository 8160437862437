import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getProjects } from '../../redux/actions/projects';
import Loading from '../../components/shared/Loading';
import Projects from '../../components/projects/Projects';
import About from '../../components/about/About';
import Photos from '../../components/photos/Photos';
import {
  shiftHeaderLogoRight,
  shiftHeaderLogoLeft,
} from '../../redux/actions/ui';

class MainContent extends Component {
  componentDidMount() {
    this.props.onGetProjects();
  }

  render() {
    const {
      projects,
      isFetchingProjects,
      apiError,
      selectedProject,
      setSelectedProject,
      onShiftHeaderLogoRight,
      onShiftHeaderLogoLeft,
    } = this.props;
    
    if (isFetchingProjects) {
      return <Loading />;
    }

    return (
      <main id="content-wrapper" className="shadow-lg clearfix">
        <Redirect exact path="/" to="/projects" />
        <Route
          path="/projects"
          render={props => (
            <Projects
              {...props}
              projects={projects}
              apiError={apiError.message}
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
              shiftHeaderLogoRight={onShiftHeaderLogoRight}
              shiftHeaderLogoLeft={onShiftHeaderLogoLeft}
            />
          )}
        />
        <Route path="/about" component={About} />
        <Route path="/photos" component={Photos} />
      </main>
    );
  }
}

MainContent.defaultProps = {
  projects: [],
  apiError: {}
};

MainContent.propTypes = {
  projects: PropTypes.array,
  isFetchingProjects: PropTypes.bool.isRequired,
  apiError: PropTypes.string,
  selectedProject: PropTypes.number.isRequired,
  setSelectedProject: PropTypes.func.isRequired,
  onGetProjects: PropTypes.func.isRequired,
  onShiftHeaderLogoRight: PropTypes.func.isRequired,
  onShiftHeaderLogoLeft: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { projects: { projectsList, isFetching } } = state;

  return {
    projects: projectsList,
    isFetchingProjects: isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onGetProjects: () => {
      dispatch(getProjects());
    },
    onShiftHeaderLogoRight: () => {
      dispatch(shiftHeaderLogoRight());
    },
    onShiftHeaderLogoLeft: () => {
      dispatch(shiftHeaderLogoLeft());
    },
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainContent)
);
