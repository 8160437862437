import React from 'react';
import PropTypes from 'prop-types';
import ProjectMainImage from '../../components/home/ProjectMainImage';
import { BASE_IMAGE_URL } from '../../constants';

const ProjectImages = ({ projectsData, currentProject }) => {
  return (
    <div id="main-images" className="shadow-lg">
      <div className="highlight-overlay" />
      <div
        className={`main-image ${!currentProject ? 'active' : ''}`}
        style={{
          backgroundImage: `url(${BASE_IMAGE_URL}/images/home-main.jpg)`,
        }}
      />
      {projectsData.map((project, index) => (
        <ProjectMainImage
          key={project.projectId}
          projectIndex={index}
          currentProject={currentProject}
          projectImage={`${BASE_IMAGE_URL}${project.projectMainImage}`}
        />
      ))}
    </div>
  )};

ProjectImages.propTypes = {
  projectsData: PropTypes.array.isRequired,
  currentProject: PropTypes.number.isRequired,
};

export default ProjectImages;