import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import ui from './ui';
import projects from './projects';
// import photos from './photos';

const appReducer = combineReducers({
  ui,
  projects,
  // photos,
  routing,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
