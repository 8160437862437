import React from 'react';
import PropTypes from 'prop-types';
import MainNavigationItem from '../../components/shared/MainNavigationItem';

const MainNavigation = ({ closeMobileNav, onShiftHeaderLogoRight }) => {
  return (
    <nav className="main">
      <ul>
        <MainNavigationItem
          title="Projects"
          to="projects"
          // closeMobileNav={closeMobileNav}
          onClick={onShiftHeaderLogoRight}
        />
        <MainNavigationItem
          title="About"
          to="about"
          // closeMobileNav={closeMobileNav}
          onClick={onShiftHeaderLogoRight}
        />
        <MainNavigationItem
          title="Photos"
          to="photos"
          // closeMobileNav={closeMobileNav}
          onClick={onShiftHeaderLogoRight}
        />
        <li>
          <a href="mailto:mail@aaronbiser.com">
            Contact
            <span className="box gradient-bg-green" />
          </a>
        </li>
      </ul>
    </nav>
  );
};

MainNavigation.defaultProps = {
  closeMobileNav: null,
  onShiftHeaderLogoLeft: null,
};

MainNavigation.propTypes = {
  closeMobileNav: PropTypes.func,
};

export default MainNavigation;
