import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { scrollTo } from '../../scripts/helpers';
import { BASE_IMAGE_URL } from '../../constants';

class ProjectNav extends Component {
  constructor(props) {
    super(props);

    this._onClickProjectPrev = this._onClickProjectPrev.bind(this);
    this._onClickProjectNext = this._onClickProjectNext.bind(this);
  }

  _onClickProjectPrev() {
    scrollTo(document.documentElement.scrollTop, 1200);
    this.props.onClickProject(this.props.projectPrevIndex);
  }

  _onClickProjectNext() {
    scrollTo(document.documentElement.scrollTop, 1200);
    this.props.onClickProject(this.props.projectNextIndex);
  }

  render() {
    const {
      projectNextName,
      projectNextImage,
      projectNextIndex,
      projectPrevName,
      projectPrevImage,
      projectPrevIndex,
    } = this.props;

    return (
      <div id="project-nav" className="clearfix">
        <h6 className="header">View More Projects</h6>
        <div className="nav-item-wrap clearfix">
          <Link
            to={`/projects/${projectPrevIndex}`}
            className="nav-item project-prev bg-noise shadow-lg"
            onClick={this._onClickProjectPrev}
            style={{
              backgroundImage: `url(${BASE_IMAGE_URL}${projectPrevImage})`,
            }}
          >
            <p>
              <span className="nav">Previous</span>
              <span className="title">
                {projectPrevName}
              </span>
            </p>
            <div className="bg" />
          </Link>

          <Link
            to={`/projects/${projectNextIndex}`}
            className="nav-item project-next bg-noise shadow-lg"
            onClick={this._onClickProjectNext}
            style={{
              backgroundImage: `url(${BASE_IMAGE_URL}${projectNextImage})`,
            }}
          >
            <p>
              <span className="nav">Next</span>
              <span className="title">
                {projectNextName}
              </span>
            </p>
            <div className="bg" />
          </Link>
        </div>
      </div>
    );
  }
}

ProjectNav.propTypes = {
  projectNextName: PropTypes.string.isRequired,
  projectNextImage: PropTypes.string.isRequired,
  projectNextIndex: PropTypes.number.isRequired,
  projectPrevName: PropTypes.string.isRequired,
  projectPrevImage: PropTypes.string.isRequired,
  projectPrevIndex: PropTypes.number.isRequired,
  onClickProject: PropTypes.func.isRequired,
};

export default ProjectNav;
