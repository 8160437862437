import React, { useEffect } from 'react';
import { useSpring, animated } from 'react-spring'
import PropTypes from 'prop-types';
import ProjectScreenshots from '../../components/projects/ProjectScreenshots';
import ProjectNav from '../../components/projects/ProjectNav';
import { addLeadingZero } from '../../scripts/helpers';

const ProjectSingle = (props) => {
  const {
    project,
    projectIndex,
    projectNextName,
    projectNextImage,
    projectNextIndex,
    projectPrevName,
    projectPrevImage,
    projectPrevIndex,
    onClickProject,
  } = props;

  useEffect(() => {
    props.shiftHeaderLogoLeft();

    if (props.match.params.project) {
      props.setProjectFromURL(props.match.params.project);
    }
    // eslint-disable-next-line
  }, [])

  const projectIndexZeroed = addLeadingZero(projectIndex, 2);

  const style1 = useSpring({ 
    config: { duration: 250 },
    opacity: 1, 
    transform: 'translate3d(0, 0, 0)',
    from: { 
      opacity: 0,
      transform: 'translate3d(0, 20px, 0)' 
    }
  })

  const style2 = useSpring({
    config: { duration: 350 },
    opacity: 1, 
    transform: 'translate3d(0, 0, 0)',
    from: { 
      opacity: 0,
      transform: 'translate3d(0, 40px, 0)' 
    }
  })

  const style3 = useSpring({ 
    config: { duration: 450 },
    opacity: 1, 
    transform: 'translate3d(0, 0, 0)',
    from: { 
      opacity: 0,
      transform: 'translate3d(0, 40px, 0)' 
    }
  })

  return (
    <animated.section id="project-wrap">
      <div id="project" className="main-centered">
        <div className="clearfix">
          <animated.div style={style1}>
          <h4>
            {project.projectSubhead}
          </h4>
          <h2>
            {project.projectName}
          </h2>
          </animated.div>

          <animated.div id="project-left" style={style2}>
            <div className="text">
              <div
                dangerouslySetInnerHTML={{
                  __html: project.projectDescription,
                }}
              />
              {project.projectContentActiveProject
                ? <p className="ca">
                    Project designed and developed by Aaron Biser for
                    <a href="http://www.contentactive.com/" target="_blank" rel="noopener noreferrer">
                      ContentActive
                    </a>
                </p>
                : null}
            </div>
          </animated.div>

          <animated.div id="project-right">
            <p className="number">
              {projectIndexZeroed}
            </p>
            <animated.ul style={style3}>
              {project.projectCategories
                ? project.projectCategories.map((category, index) => {
                  return (
                    <li key={category}>
                      {category}
                    </li>
                  );
                })
                : null}
            </animated.ul>
            {project.projectWebsite
              ? <p className="link">
                <a
                  className="shadow-md bg-noise"
                  href={project.projectWebsite}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                    View Website <i className="icon" />
                </a>
              </p>
              : null}
          </animated.div>
        </div>

        <ProjectScreenshots
          projectId={parseFloat(project.projectId)}
          projectName={project.projectName}
          projectScreenshots={project.projectScreenshots}
        />

        <ProjectNav
          projectNextName={projectNextName}
          projectNextImage={projectNextImage}
          projectNextIndex={projectNextIndex}
          projectPrevName={projectPrevName}
          projectPrevImage={projectPrevImage}
          projectPrevIndex={projectPrevIndex}
          onClickProject={onClickProject}
        />
      </div>
    </animated.section>
  );
}

ProjectSingle.defaultProps = {
  project: {},
};

ProjectSingle.propTypes = {
  project: PropTypes.shape({
    projectId: PropTypes.string,
    projectName: PropTypes.string,
    projectMainImage: PropTypes.string,
    projectSubhead: PropTypes.string,
    projectCategories: PropTypes.array,
    projectContentActiveProject: PropTypes.string,
    projectDescription: PropTypes.string,
    projectWebsite: PropTypes.string,
    projectScreenshots: PropTypes.array,
  }).isRequired,
  projectIndex: PropTypes.number.isRequired,
  projectNextName: PropTypes.string.isRequired,
  projectNextImage: PropTypes.string.isRequired,
  projectNextIndex: PropTypes.number.isRequired,
  projectPrevName: PropTypes.string.isRequired,
  projectPrevImage: PropTypes.string.isRequired,
  projectPrevIndex: PropTypes.number.isRequired,
  onClickProject: PropTypes.func.isRequired,
  setProjectFromURL: PropTypes.func.isRequired,
  shiftHeaderLogoLeft: PropTypes.func.isRequired,
};

export default ProjectSingle;
