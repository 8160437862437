import React from 'react';
import { Link } from 'react-router-dom';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { BASE_IMAGE_URL } from '../../constants';

const About = () =>
  (<ReactCSSTransitionGroup
    transitionName="about"
    transitionAppearTimeout={1250}
    transitionAppear
    transitionEnterTimeout={500}
    transitionLeaveTimeout={300}
  >
    <div id="about-me">
      <div
        className="main-image shadow-lg"
        style={{
          backgroundImage: `url(${BASE_IMAGE_URL}/images/about-header.jpg)`,
        }}
      />

      <div className="about-wrap clearfix">
        <div className="about-left">
          <div className="headline-box shadow-lg bg-noise">
            <div className="inner">
              <h1>Aaron Biser</h1>
              <h2>Developer & UI Designer</h2>
              <hr />
              <hr />
            </div>
          </div>
          <div className="text">
            <p>
              Hi, I'm Aaron Biser and I make beautiful web things using HTML,
              CSS, Javascript, React & React Native.
            </p>
            <p>
              I do exactly what I love to do every single day. It’s easy to get
              up in the morning knowing that I have the opportunity to create
              something brand new. I believe that form and function go hand in
              hand and user experience should never be compromised.
            </p>
            <p>
              Outside of work I enjoy anything that involves exercise, sunshine
              and fresh air. Including running, hiking, biking, photography and
              traveling around the world.
            </p>
            <p>
              Feel free to{' '}
              <a href="mailto:mail@aaronbiser.com?subject=Oh Hello">
                contact me
              </a>{' '}
              and say hi or take a look at some of my{' '}
              <a href="/photos">travel photos</a>.
            </p>
            <p />
            <p>&#60;&#47;aaron&#62;</p>
          </div>
        </div>

        <div className="about-right">
          <div className="photo photo-1 shadow-lg clearfix">
            <img src={`${BASE_IMAGE_URL}/images/travel-pic-1.jpg`} alt="Aaron Biser Travel" />
          </div>
          <div className="photo photo-2 shadow-lg clearfix">
            <img src={`${BASE_IMAGE_URL}/images/travel-pic-2.jpg`} alt="Aaron Biser Travel" />
            <Link to="/photos" className="travel-photos shadow-lg bg-noise">
              View My Travel Photos <i className="icon" />
            </Link>
          </div>
          <div className="photo photo-3 shadow-lg clearfix">
            <img src={`${BASE_IMAGE_URL}/images/travel-pic-3.jpg`} alt="Aaron Biser Travel" />
          </div>
        </div>
      </div>
    </div>
  </ReactCSSTransitionGroup>);

export default About;
